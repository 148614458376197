import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { ColorDrawerStyleItemThumb } from "./ColorDrawerStyleItemThumb";
import {
  selectSelectedStyleItem,
  updateSelectedStyleItem,
  selectFirstStyleItemsFiltered,

} from "../store/orderingSlice";
import { InvalidSVG } from "./InvalidSVG";
import { ColorDrawerAvailableVariants } from "./ColorDrawerAvailableVariantsItem";


function ColorDrawerInfoTag({bestSeller = false , newItem = false, closeoutItem = false, saleItem = false})
{

  if(closeoutItem) {
    return <div className="color-drawer__item-info-tag color-drawer__item-info-tag--clearance-item">Clearance</div>;
  }

  if(saleItem) {
    return <div className="color-drawer__item-info-tag color-drawer__item-info-tag--sale-item">Sale</div>;
  }

  
  if(bestSeller) {
    return <div className="color-drawer__item-info-tag color-drawer__item-info-tag--best-seller">Best Seller</div>;
  }

  if(newItem) {
    return <div className="color-drawer__item-info-tag color-drawer__item-info-tag--new-item">New</div>;
  }

  return <></>;

}

function generateStyleItemStatusClassNames(base_class_name,si,is_selected)
{
  return classNames({
    [base_class_name] : true,
    [base_class_name + "--is-invalid"]: !si.valid,
    [base_class_name + "--is-valid"]: si.valid,
    [base_class_name + "--is-selected"]: is_selected,
    [base_class_name + "--is-backordered"]: si.backordered,
    [base_class_name + "--is-low-stock"]: si.low_stock
  });
}

export function ColorDrawerStyleList({ style }) {
  const dispatch = useDispatch();

  const selected_style_item = useSelector(
    selectSelectedStyleItem(style.style_ref)
  );

  const sorted_style_items = useSelector(selectFirstStyleItemsFiltered);

  const onStyleItemClick = (clicked_style_item_ref) => {
    dispatch(
      updateSelectedStyleItem({
        style_ref: style.style_ref,
        style_item_ref: clicked_style_item_ref,
      })
    );
  };

  return (
    <ul className="color-drawer__list">
      {sorted_style_items.map((si) => {
        const is_selected =
          si.style_item_ref === selected_style_item?.style_item_ref;

        const button_class = generateStyleItemStatusClassNames(
          "color-drawer__item-button",
          si,
          is_selected
        );
        const image_class = generateStyleItemStatusClassNames(
          "color-drawer__item-image",
          si,
          is_selected
        );
        const item_name_class = generateStyleItemStatusClassNames(
          "color-drawer__item-name",
          si,
          is_selected
        );
        const item_model_class = generateStyleItemStatusClassNames(
          "color-drawer__item-model",
          si,
          is_selected
        );
        const item_image_wrap_class = generateStyleItemStatusClassNames(
          "color-drawer__item-image-wrap",
          si,
          is_selected
        );
        const item_image_status_wrap_class = generateStyleItemStatusClassNames(
          "color-drawer__item-image-status-wrap",
          si,
          is_selected
        );
        
        return (
          <li className="color-drawer__item" key={si.style_item_ref}>
            <button
              className={button_class}
              onClick={() => onStyleItemClick(si.style_item_ref)}
            >
              <div className={item_image_wrap_class}>
                <div className={item_image_status_wrap_class}>
                  <ColorDrawerStyleItemThumb
                    className={image_class}
                    styleItem={si}
                  />
                </div>
                <InvalidSVG show={!si.valid} />                
                {si.low_stock &&
                  <span
                    className="color-drawer__item-image-icon color-drawer__item-image-icon--is-low-stock"
                    title="Low Stock"
                  ></span>
                }
              </div>
              <div className="color-drawer__item-text-wrap">
                <ColorDrawerInfoTag 
                  bestSeller={si.best_seller}
                  newItem={si.new_item}
                  closeoutItem={si.closeout_item}
                  saleItem={si.sale_item}

                />
                <div className={item_name_class}>{si.style_item_name}</div>
                <div className={item_model_class}>{si.style_item_model}</div>
                {si.available_variants?.length &&
                  <ColorDrawerAvailableVariants 
                    variants={si.available_variants}
                  />
                }                
              </div>
              {is_selected && <span
                className="color-drawer__selected-indicator"
                ></span>
              }
            </button>
          </li>
        );
      })}
    </ul>
  );
}
