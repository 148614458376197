import { bulkOrderStockNotifyClick } from "../store/orderingSlice";
import { useDispatch } from "react-redux";
import { 
  availableTextHasDays,
  availableTextHasPlus 
} from "../utilities/product_utilities";
import classNames from "classnames";

//Internal Use Message Sub Components
function BulkOrderNotifyLink({notifyCode})
{
  const dispatch = useDispatch();
  return (
    <a 
      className="notify_stock alert-link"
      href={"/notifystock.html?pcode=" + notifyCode}
      onClick={e => {
        e.preventDefault();
        dispatch(bulkOrderStockNotifyClick(notifyCode))
      }}
    >Email me when this item is back in stock</a>
  );
}

function BulkOrderNoWebOrderingMessage() {
  return <>The item you have selected is not available for purchase online. See the Overview below for ordering instructions.</>;
}

function BulkOrderPreReleaseMessage({availableText})
{
  return <>This item will be released on {availableText} and is not yet available.</>;
}

function BulkOrderPreOrderMessage({availableText,notifyStock = false, notifyCode = ''})
{
  return (
    <>
      <>This item is available for Pre-Order with an estimated arrival date of {availableText}. </>
      {notifyStock && notifyCode &&  <><BulkOrderNotifyLink notifyCode={notifyCode} />.</>}
    </>
  );
}

function BulkOrderAvailableMessage({availableText})
{
  const is_singular = parseInt(availableText) === 1;
  return <>There {is_singular?'is':'are'} only {availableText} left in stock.</>;
}

function BulkOrderNoBackorderMessage({availableText, notifyStock = false, notifyCode = ''})
{
  return (
    <>
      <>This item is back ordered and unavailable for purchase at this time. </>
      {notifyStock && notifyCode &&  <><BulkOrderNotifyLink notifyCode={notifyCode} />.</>}
    </>    
  );
}

function BulkOrderOutOfStockMessage() {
  return <>This item is out of stock</>;
}


function BulkOrderBackorderMessage({availableText, notifyStock = false, notifyCode = ''})
{
  if(availableTextHasDays(availableText))
  {
    return <>This item will be ready to ship in approximately {availableText} after you place your order.</>;
  }
  else
  {
    if(availableText && !availableTextHasPlus(availableText))
    {
      return (
        <>
          <>This item is available for back order. It is expected to be available to ship on {availableText}. </>
          {notifyStock && notifyCode &&  <><BulkOrderNotifyLink notifyCode={notifyCode} />.</>}
        </>
      );
    }
  }
}

function BulkOrderStockMessageWrapper({children, hasSize = false, alertType = "warning", messageStatus})
{

  const class_name = classNames({
    "bulk-drawer__alert": true,
    "alert": true,
    "alert-warning": alertType === 'warning',
    "is-invalid": messageStatus === "Invalid",
    "is-backordered": messageStatus === "Backorder",
    "is-low-stock": messageStatus === "LowStock"
  });


  return (<tr className="bulk-drawer__alert-row">
      <td colSpan={hasSize ? 3 : 2}>
        <div className={class_name}>
          {children}
        </div>
      </td>
    </tr>
  )
}

export function BulkOrderStockMessage(props) {

  const valid_types = {
    "PreRelease": BulkOrderPreReleaseMessage,
    "NoWebOrdering": BulkOrderNoWebOrderingMessage,
    "OutOfStock": BulkOrderOutOfStockMessage,
    "NoBackorder": BulkOrderNoBackorderMessage,
    "PreOrder": BulkOrderPreOrderMessage,
    "Backorder": BulkOrderBackorderMessage,
    "Available": BulkOrderAvailableMessage
  };

  if(props.messageType && typeof valid_types[props.messageType] !== "undefined")
  {
    const MessageComponent = valid_types[props.messageType];
    
    const message_wrap_props = {}
    if(props.messageStatus)
    {
      message_wrap_props.messageStatus = props.messageStatus;
    }

    return (
      <BulkOrderStockMessageWrapper hasSize={props.hasSize} {...message_wrap_props}>
        <MessageComponent {...props} /> 
      </BulkOrderStockMessageWrapper>
    );
  }
  else
  {
    return <></>;
  }
}