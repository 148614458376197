import classNames from "classnames";

function ColorDrawerAvailableVariantsItem({ variant }) {
  const class_name = classNames({
    "color-drawer__size": true,
    "color-drawer__size--is-backordered": variant.backordered,
    "color-drawer__size--is-low-stock": variant.low_stock
  });

  return (
    <li className={class_name}>
      {variant.label}
      {variant.low_stock && (
        <span
          className="color-drawer__size-icon color-drawer__size-icon--is-low-stock"
          title="Low Stock"
        ></span>
      )}
    </li>
  );
}

export function ColorDrawerAvailableVariants({ variants }) {
  return (
    <>
      <div className="color-drawer__sizes-label">Available In:</div>
      <ul className="color-drawer__sizes-list">
        {variants.map((variant, index) => (
          <ColorDrawerAvailableVariantsItem key={index} variant={variant} />
        ))}
      </ul>
    </>
  );
}
