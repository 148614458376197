import { specsOrderingStockNotifyClick } from "../store/orderingSlice";
import { useDispatch } from "react-redux";
import { 
  availableTextHasDays,
  availableTextHasPlus 
} from "../utilities/product_utilities";
import { getStockMessageData } from "../utilities/product_utilities";
import classNames from "classnames";

//Internal Use Message Sub Components
function StockMessageNotifyLink({notifyCode})
{
  const dispatch = useDispatch();
  return (
    <a 
      className="notify_stock alert-link"
      href={"/notifystock.html?pcode=" + notifyCode}
      onClick={e => {
        e.preventDefault();
        dispatch(specsOrderingStockNotifyClick(notifyCode));
      }}
    >Email me when this item is back in stock</a>
  );
}

function StockMessageUnavailable() {
  return <>The selected item is unavailable.</>;
}

function StockMessageNoWebOrdering() {
  return <>The item you have selected is not available for purchase online. See the Overview below for ordering instructions.</>;
}

function StockMessagePreRelease({availableText})
{
  return <>This item will be released on {availableText} and is not yet available.</>;
}

function StockMessagePreOrder({availableText,notifyStock = false, notifyCode = ''})
{
  return (
    <>
      <>This item is available for Pre-Order with an estimated arrival date of {availableText}. </>
      {notifyStock && notifyCode && <><StockMessageNotifyLink notifyCode={notifyCode} />.</>}
    </>
  );
}

function StockMessageAvailable({availableText})
{
  const is_singular = parseInt(availableText) === 1;
  return <>Low Stock - There {is_singular?'is':'are'} only {availableText} left.</>;
}

function StockMessageNoBackorder({availableText, notifyStock = false, notifyCode = ''})
{
  return (
    <>
      <>This item is back ordered and unavailable for purchase at this time. </>
      {notifyStock && notifyCode &&  <><StockMessageNotifyLink notifyCode={notifyCode} />.</>}
    </>    
  );
}

function StockMessageOutOfStock() {
  return <>This item is out of stock</>;
}


function StockMessageBackorder({availableText, notifyStock = false, notifyCode = ''})
{
  if(availableTextHasDays(availableText))
  {
    return <>This item will be ready to ship in approximately {availableText} after you place your order.</>;
  }
  else
  {
    if(availableText && !availableTextHasPlus(availableText))
    {
      return (
        <>
          <>This item is available for back order. It is expected to be available to ship on {availableText}. </>
          {notifyStock && notifyCode &&  <><StockMessageNotifyLink notifyCode={notifyCode} />.</>}
        </>
      );
    }
  }
}

function StockMessageWrapper({children, alertType = "warning", messageStatus })
{
  const class_name = classNames({
    "alert": true,
    "alert-danger": alertType === 'danger',
    "alert-warning": alertType === 'warning',
    "is-invalid": messageStatus === "Invalid",
    "is-backordered": messageStatus === "Backorder",
    "is-low-stock": messageStatus === "LowStock"
  });

  return (
    <div className={class_name}>
      {alertType === 'danger' && <span class="icon icon-warning"></span>}
      {children}
    </div>
  )
}

export function SelectedStockMessage({matches}) {
  
  const stock_message_data = getStockMessageData(matches);
  
  if(stock_message_data === null)
  {
    return <></>
  }
  const valid_types = {
    "Unavailable": StockMessageUnavailable,
    "PreRelease": StockMessagePreRelease,
    "NoWebOrdering": StockMessageNoWebOrdering,
    "OutOfStock": StockMessageOutOfStock,
    "NoBackorder": StockMessageNoBackorder,
    "PreOrder": StockMessagePreOrder,
    "Backorder": StockMessageBackorder,
    "Available": StockMessageAvailable
  };

  const danger_types = [
    "Unavailable",
    "OutOfStock"
  ];

  if(stock_message_data.messageType && typeof valid_types[stock_message_data.messageType] !== "undefined")
  {
    const MessageComponent = valid_types[stock_message_data.messageType];
    const message_wrap_props = {}
    if(danger_types.indexOf(stock_message_data.messageType) !== -1)
    {
      message_wrap_props.alertType = "danger";
    }
    if(stock_message_data.messageStatus)
    {
      message_wrap_props.messageStatus = stock_message_data.messageStatus;
    }
    return <StockMessageWrapper {...message_wrap_props }>
      <MessageComponent {...stock_message_data} /> 
    </StockMessageWrapper>
  }
  else
  {
    return <></>;
  }
}